
import {defineComponent, onMounted, reactive, toRefs, computed} from "vue";
import ApiLogistics from "@/request/apis/api_logistics";
import router from "@/router";
import {message} from "ant-design-vue/es";
import StorageUtil from "@/utils/storage_util";
import {EditOutlined, CheckOutlined, CloseOutlined} from '@ant-design/icons-vue'
import ImageView from '@/components/ImageView.vue'
import DriverSet from '@/components/DriverSet.vue'
import PageUtil from "@/utils/page_util";
import {ICashItem} from "@/models/logistics_model";
import {dateFormat} from "@/utils/common";

interface IState {
  list: ICashItem[],
  total: number;
  loading: boolean;
  searches: any
  visible: boolean
  item?: ICashItem
  reason: string
  type?: 1 | 2, // 1提现审核，2打款确认
}

// 表格
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '申请人',
    dataIndex: 'full_name',
    key: 'full_name',
  }, {
    title: '打款银行',
    dataIndex: 'bank_name',
    key: 'bank_name',
  },
  {
    title: '银行卡号',
    dataIndex: 'bank_card_number',
    key: 'bank_card_number',
  },
  {
    title: '提现金额(元)',
    dataIndex: 'amount',
    key: 'amount',
    align: 'right'
  },
  {
    title: '申请时间',
    dataIndex: 'create_time',
    key: 'create_time',
  },
  {
    title: '审核信息',
    dataIndex: 'audit_time',
    key: 'audit_time',
  },
  {
    title: '打款信息',
    dataIndex: 'transfer_time',
    key: 'transfer_time',
  },
  {
    title: '状态',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: '操作',
    key: 'action',
    width: 250
  },
];

export default defineComponent({
  name: 'CashOutList',
  components: {EditOutlined, CheckOutlined, CloseOutlined, ImageView, DriverSet},
  setup() {
    const state: IState = reactive({
      list: [],
      total: 0,
      loading: false,
      visible: false,
      item: undefined,
      reason: '', //备注
      type: undefined,
      searches: {
        page_no: PageUtil.pageNo,
        page_size: PageUtil.pageSize,
      },
    })

    // 状态
    const statusText = [{id: 0, name: '未审核'}, {id: 1, name: '审核成功'},{id: 2, name: '审核失败'},{id: 3, name: '打款成功'},{id: 4, name: '打款失败'}]

    const getStatus = (status: number) => {
      return statusText.find(e => e.id === status)?.name
    }

    const pagination = computed(() => ({
      total: state.total,
      current: state.searches.page_no,
      pageSize: state.searches.page_size,
      showTotal: (total: any) => `共${total}条数据`,
      defaultPageSize: 10,
      // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
      showSizeChanger: true, // 是否显示pageSize选择
      showQuickJumper: true, // 是否显示跳转窗
    }));

    // 列表当前页更改
    const handleTableChange = (pages: any) => {
      state.searches.page_size = pages.pageSize
      state.searches.page_no = pages.current
      getList()
    };

    //获取列表
    const getList = async () => {
      let params = {...state.searches}
      StorageUtil.setParams(params)
      state.loading = true
      const res = await ApiLogistics.getCashOutList(params, 'user')
      state.loading = false
      if (res && res.error_code === 0) {
        state.total = res.data?.count || 0
        state.list = res.data?.list || []
      }
    }

    // 设置modal显示隐藏
    const setVisible = (item: ICashItem | undefined, flg: boolean, type: 1 | 2 | undefined = undefined) => {
      state.visible = flg
      state.item = item
      state.reason = ''
      if(type){
        state.type = type
      }else{
        setTimeout(() => state.type = type, 300)
      }
    }

    // 审核
    const onAudit = async (flg: boolean) => {
      state.loading = true
      const res = await ApiLogistics.auditCashOut({
        "id": state.item?.id,
        "is_pass": flg,
        "audit_remark": state.reason
      })
      state.loading = false
      if (res && res.error_code === 0) {
        message.success('操作成功')
        await getList()
      } else {
        message.error(res?.msg || '审核失败');
      }
      setVisible(undefined, false)
    }

    // 打款
    const auditCashTransfer = async (flg: boolean) => {
      state.loading = true
      const res = await ApiLogistics.auditCashTransfer({ "id":state.item?.id, "transfer_remark":state.reason, "is_transfer": flg })
      state.loading = false
      if (res && res.error_code === 0) {
        message.success('操作成功')
        await getList()
      } else {
        message.error(res?.msg || '审核失败');
      }
      setVisible(undefined, false)
    }

    // 搜索
    const onSearch = (key: string, value: any, e: any) => {
      state.searches[key] = value
      state.searches.page_no = 1
      state.searches.page_size = PageUtil.pageSize
      getList()
    }

    // 重置search参数
    const resetSearch = () => {
      for (let key in state.searches) {
        state.searches[key] = ''
      }
      state.searches.page_no = 1
      state.searches.page_size = PageUtil.pageSize
      getList()
    }

    // 初始化search参数
    const initSearch = () => {
      const res = StorageUtil.getParams()
      if (res) {
        state.searches = {...state.searches, ...res}
      }
    }

    onMounted(() => {
      initSearch()
      getList()
    })

    return {
      ...toRefs(state),
      columns,
      pagination,
      handleTableChange,
      onSearch,
      resetSearch,
      onAudit,
      setVisible,
      dateFormat,
      auditCashTransfer,
      getStatus
    }
  }
})
